import React from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import "./multiLanguage.css"

import { useState,useEffect } from "react";


export const languageMap = {
    en: { label: "English", dir: "ltr", active: true,select:'select' ,font:"'Roboto', sans-serif"},
    he: { label: "עברית", dir: "rtl", active: false,select:'בחר' ,font:'Arial'},
};


export const AppText = {
  //PAGES
  pois:{
    categories:{
      history:{en:"History",he:"היסטוריה"},
      saved:{en:"My POI's",he:"הנקודות שלי"},

    }
  },
  errors: {
    inputTooShort: {
        en: 'Input must be at least 2 characters long.',
        he: 'חייב להיות לפחות 2 תווים'
    }
  },
  userOrder:{
    no_server_connection:{en:'No server connection',he:'אין חיבור לשרת'},
    tap_to_order:{ en:'Tap to order cart' , he :'לחץ להזמנת רכב'},
    search:{en:'Searching for cart', he:'מחפשים רכב זמין'},
    available:{en:'Cart is available ',he:'רכב זמין לנסיעה '},
    not_available:{en:"No available cart",he:'אין רכב זמין כעת'},
    advanced:{en:'ADVANCED' , he:'מתקדם'},
    cart_will_be_available:{en:'Try again within :' , he:': נסה שוב בעוד'},
    cant_found_cart:{en:"Can't find a cart",he:'לא ניתן למצוא רכב כרגע'},
    something_went_wrong:{en:'Something went wrong',he:'משהו הלך לא כשורה'},
    cart_found:{en:'Perfect, We found a cart for you!',he:'!מעולה, מצאנו רכב בשבילך'},
    far_away_massage:{en:'Please get closer to any road .',he:'בבקשה התקרב למסלול הנסיעה'},

  },
  time_of_day:{
    morning:{en:'Good morning',he:'בוקר טוב'},
    afternoon:{en:'Good afternoon',he:'צהריים טובים'},
    evening:{en:'Good evening',he:'ערב טוב'}

  },
  voiceCall:{
    incomingHeader:{en:'Incoming call from support',he:'שיחה נכנסת מהתמיכה'},
    answerButton:{en:'Answer',he:'ענה'},
    declinceButton:{en:'Decline',he:'סרב'},
    adminNotAvailable:{en:'Support not available',he:'אין זמינות כרגע'}
  },
  Buttons:{
    resetToGps:{en:'Reset to pickup from GPS',he:'GPS - אפס מיקום'},
    choosePoi:{en:'Choose POI',he:'בחר יעד'},
    understood_continue:{en:'Understood, continue' ,he:'הבנתי, המשך'},
    choose_destination:{en:'Choose destination by map',he:'בחר יעד באמצעות המפה'},
    save_point:{en:'Save point',he:'שמור נקודה'},
    save_and_start:{en:'Save&',he:'שמור נקודה'},
    cancel:{en:'Cancel',he:"סיים"},
    finish:{en:'Finish',he:'סיום'},
    finish_without_save:{en:'Just drive',he:'בוא ניסע לשם'},
    contact_me_to_order_cart:{en:'Contact me to schedule a ride',he:"צרו איתי קשר לתיאום נסיעה"},
  },
  POI:{
    poiCardOpen:{en:'Open',he:'פתוח'},
    categoryCard:{en:'Open',he:'פתוח'},
    boxesList:{
      history:{en:'HISTORY',he:"היסטוריה"},
      category:{en:'CATEGORY',he:"קטגוריות"}
    },
    selectDestination:{en:'Select Destination',he:'בחר יעד'}
  },
  confirmDestinantion:{
    nextDestination:{en:'Next Destination:' , he:' : היעד הבא '}
  },
  registration:{
    welcome:{en:'Welcome to Auto-Pickup service of',he:' ברוכים הבאים לשירות ההסעות האוטונומי -'},
    rgister:{en:'Register' , he:'הירשם'},
    registration:{en:'Registration' , he:'הרשמה'},
    confirm_email:{en:'Confirm Email' , he:'אשר מייל'},
    first_name:{en:'First name',he:'שם פרטי'},
    last_name:{en:'Last name',he:'שם משפחה'},
    email:{en:'Email Address',he:'מייל'},
    phone:{en:'Phone Number',he:'נייד'},
    err_massage:{en:'Please check the invalid fields !',he:'בבקשה תבדוק את השדות הלא חוקיים '},
    top_err_message:{en:'please fill all the details below before register.',he:'אנא מלא את כל הפרטים למטה לפני ההרשמה.'},
    link_register_msg:{en:'Decide which method to use for receiving a registration link',he:'החלט באיזו שיטה להשתמש לקבלת קישור הרשמה'},
    send_by_email:{en:'Send via Email',he:'שלח במייל'},
    send_by_sms:{en:'Send via SMS',he:'שלח ב-SMS'},
    send_by_whatsapp:{en:'"Send via WhatsApp"',he:'שלח ב-Whatsapp'},

    errors:{
      name:{en:'Name must be at least 2 characters !',he:'  2 אותיות לפחות(אסורים תווים אחרים)'},
      email:{en:'Email is not valid!',he:'מייל אינו חוקי'},
      email_match:{en:'Emails is not equals',he:'מייל לא זהה'},
      phone:{en:'Number is invalid',he:'מספר לא חוקי'}
    },
    text_massage:{
      hey:{en:'Hey ' , he:' שלום '},
      link:{en:'\nThanks for registering to auto-pickup service of Carteav.\nYour link is:\n',he:'\nתודה שנרשמת לשירות ההסעות האוטונומי של Carteav.\n הקישור שלך הינו:\n' }
    },
  },
  cart_not_available:{
    no_carts_available:{en:"No carts available at this moment",he:"אין רכב זמין כרגע"},
    service_closed:{en:"Service is closed at this moment",he:"השירות סגור כרגע"},
    please_try:{en:"Please try again in ", he:"בבקשה נסה שוב בעוד"},
    minutes:{en:"minutes",he:"דקות"},
    check_for_availability:{en:"Checks for availability", he:"בודק זמינות"},
    someone_will_contact:{en:"Someone will contact you soon",he:"מישהו ייצור איתך קשר בקרוב"}
  },
  poiContainer:{
    change:{en:'Change', he:'החלף'},
    default:{en:'Default Point', he:'ברירת מחדל'},
    gps:{en:"GPS location",he:'GPS מיקום'},
    open:{en:'Open', he:'פתוח'},
    close:{en:'Close', he:'סגור'},
    save:{en:'Save', he:'שמור'},
    searchBar:{en:"Enter address(inside map) or POI" ,he:'הכנס כתובת(בתוך המפה) או יעד'}
  },
  bottomBar:{
    release:{en:"Release" , he:'סיום נסיעה'},
    approveDrive:{en:"Start driving" , he:'התחל נסיעה'},
    add_another_stop:{en:'Add Another Stop' , he:'הוסף עוד עצירה'},
    destination_limit:{en:'Destinations Limit' , he:'הגעת למקסימום נסיעות'},
    pause_cart_driving:{en:'Pause Driving' , he:'עצור נסיעה'},
    resume_cart_driving:{en:'Resume Driving' , he:'המשך נסיעה'},
    cancel:{en:'Cancel ride',he:'ביטול נסיעה'},
    order_now:{en:'Order now',he:'הזמן רכב'},
    pickup:{en:'Pickup',he:'נ.איסוף'},
    lets_go:{en:'Lets go!', he:'! בוא נצא לדרך'},
    destionation:{en:'Destination',he:'יעד'}
  },
  map_label_massage:{
    default:{en:'You got a default point',he:'מיקום ברירת מחדל'},
    default_err:{en:'GPS permissions error',he:'בעיה בהרשאות מיקום'},
    gps:{en:"Your location",he:'המיקום שלך'},
    choose_location:{en:'Chosen location',he:'מיקום נבחר'},
  },
  
  driving:{
    cart_on_the_way:{en:"It's on the way",he:'איזה כיף, הרכב בדרך אליך'}
  },
  massageBar:{
    enterCart:{en:"Please enter cart before picking a destination",he:'בבקשה היכנס לרכב לפני בחירת היעד'},
    pick_destination:{en:"Pick a destination",he:'לאן תרצה להגיע'},
    cart_in_point:{en:"Cart in point,please choose another destination",he:'הרכב נמצא בנקודה, בבקשה בחר יעד חדש'},
    
    poi_without_path:{en:"There is no path to selected destination",he:' אין מסלול לנקודה שנבחרה ,בחר יעד חדש'},
    looking_for_road:{en:"Looking for a road...",he:'...מחשב מסלול'},
    ready_to_go:{en:"The cart is ready to go!",he:'!הרכב מוכן לנסיעה'},
    cart_on_the_way:{en:"The cart is on the way",he:'!הרכב בנסיעה'},
    arrive_to_destination:{en:"You reached to your destination", he:'הגעת ליעד'},
    cancel:{en:'Cancel',he:"סיים"},
    auto_release:{en:"cart will automatically release in",he:"הרכב ישתחרר בתוך"},
    going_in:{en:"Going in...",he:"...נוסעים בעוד"},
    selectPickup:{en:"Select a pickup location",he:"בחר יעד לאיסוף"},
    cart_blocked:{en:'Cart is blocked' , he:'הרכב חסום'},
    cart_stopped:{en:'Cart is stopped for a while' , he:'הרכב עצר לרגע'},
    something_went_wrong:{en:'Something went wrong, please try again',he:'משהו השתבש, בבקשה נסה שוב'},

    cartError:{en:'Cart mulfunction - please call for help', he:'נא להתקשר לעזרה , תקלה ברכב'},  
    non_stop_area:{en:'Unstoppable zone',he:'אזור ללא עצירה'}
  
  },
  // COMPONENTS
  install:{en:'Install App', he:"התקן אפליקציה"},
  mapIcon:{
    drag_icon:{en:'Pin point is draggable',he:'ניתן להזיז את המיקום '},
    user_out_from_bounderis:{en:"We could'nt find your location" , he:'לא הצלחנו למצוא את המיקום שלך'},
    default_position:{en:'You got default position' , he:'קיבלת מיקום ברירת מחדל'}
    
  },
  callButton:{
    call:{en:'Call assistance' , he:'התקשר לקבלה'},
    leave:{en:'Tap to leave' , he:'נתק שיחה'}
  },
  seconds:{en:'Seconds' , he:'שניות'},
  minutes:{en:'Minutes' , he:'דקות'},
  cardData:{
    TimerBeforCarStartDrive:{en:'Cart will go in ' ,he:'הרכב יתחיל לנסוע בעוד '},
    order:{en:'Order' , he:'הזמן'},
    im_on_cart:{en:"I'm on the Cart",he:'אני ברכב '},
    set_destination:{en:"Set destination",he:'בחר יעד'},
  },
  cardDetails:{
    cart_details:{en:'Cart details', he:'פרטי הרכב'},
    seats:{en:'seats' , he:'מקומות'},
    cart_of:{en:'Cart of ' , he:"רכב בעל "},
  },
  Timer:{
    until:{en:'until' , he:'עד'},
    arriving:{en:'arriving' , he:'הגעה'},
    wait_for:{en:'Looking for ' , he:'מחפשים'},
    data:{en:'cart for you', he:'רכב בשבילך'},
    path_not:{en:"Sorry, it's" , he:'מצטערים, זה'},
    found:{en:'far away for us' , he:'קצת רחוק לנו '},
    try_again:{en:'wrong, try again',he:'נסה שוב'}
  },
  TimerLoadingOnTheWay:{
    wait:{en:'wait',he:'המתן'},
   
  },
  //MASSAGES 
  messages:{
    release:{
      title:{en:'Are you sure you want to end this ride?',he:'?בטוח שאתה רוצה לסיים את הנסיעה'},
      yes_btn:{en:'Yes,cancel ride',he:'כן, סיים נסיעה'},
      no_btn:{en:'Keep driving',he:'המשך נסיעה'},
      close_dialog:{en:'Close',he:'סגור'}
    },
    connection_error:{
      title:{en:'Connection error' , he:'אין תקשורת'},
      text:{en:'wait for reservation data. Please try again....' , he:'ממתין לנתוני הזמנה. נסה שוב'}
    },
    Pause_Drive:{
      title:{en:'Pause/Drive Cart' , he:'עצירת נסיעה'},
      text:{en:'Cart is offline, please try again' , he:'הרכב לא זמין, נסה שוב'}
    },
    pickup:{
      title:{en:'Pickup error' , he:'שגיאה בנסיעה ליעד '}
    },
    location:{
      title:{en:'Location error' , he:'שגיאת מיקום'}
    },
    connection_lost:{
      title:{en:'Connection Lost !' , he:'התקשורת אבדה'}
    },
    connection_problem:{
      title:{en:'Connection Problem ...' , he:'...בעיית תקשורת'}
    },
    path_not_found:{
      title:{en:'Path not found' , he:'לא נמצא מסלול'},
      text:{en:'Could not found relevant path' , he:'לא ניתן למצוא מסלול רלוונטי'}
    },
    cart_location:{
      title:{en:'Cart location' , he:'שים לב'},
      text1:{en:'The cart already at' , he:'הרכב נמצא כעת ב '},
      text2:{en:'Please choose other destination.' , he:'בבקשה תבחר יעד חדש '},
    },
    cart_is_offline:{
      title:{en:'Cart is offline' , he:'הרכב לא זמין '},
      text:{en:'Try again ! ' , he:'נסה שוב '}
    },
    registration_error:{
      title:{en:'Registration Error' , he:'שגיאה בהרשמה '}
    },
    registration_success:{
      title:{en:'Registration Successful!' , he:'ההרשמה הצליחה'},
      text:{en:'The link will be sent to you by' , he:'הקישור נשלח כעת בהודעת'},
    },
    customer_already_register:{
      title:{en:'Customer already exist' , he:'משתמש קיים'},
      text:{en:'The link will be sent to you by' , he:' הקישור נשלח כעת בהודעת '},
      
    },
    user_in_use:{
      title:{en:'User In Use',he:'משתמש זה כרגע בשימוש'},
      text:{en:'contintue any way',he:'מעוניין להמשיך בכל זאת'}
    },
    user_invalid:{en:'User Invalid',he:'משתמש לא חוקי'},
    reservation_limit:{en:'Reservation limit',he:'מקסימום הזמנות '},
    cant_found_cart:{en:"Can't find avaliable cart",he:'אין רכב זמין כרגע'},
    portrait_mode:{
      text:{en:'Oops... ',he:'...מצטערים'},
      title:{en:"Please back to portrait mode. \n We don't support in landspace mode",he:'בבקשה חזור למצב דיוקן, \nאנחנו לא תומכים במצב זה כעת'}
    },
    inputDialog:{
      title:{en:'Add point name',he:'הוסף כינוי לנקודה'},
      inputLabel:{en:'Insert value',he:'הכנס טקסט'},
    },
  },
  alert:{
    pay_attention:{en: 'Pay attention !',he:'! שים לב'},
    already_in_point:{en: "You're exactly at this point 🙂",he:'🙂 אתה נמצא בדיוק כאן'},
    choose_new_destination:{en:"Please choose another destination",he:'בבקשה בחר יעד חדש'},
    we_sorry:{en:"We're sorry 😇",he:'😇 אנחנו מצטערים'},
    point_not_close_to_any_road:{en:"This point not closed enough to any road.",he:'הנקודה לא קרובה מספיק למסלול'},
    poi_without_path:{en:"At the moment,There is no path to selected destination",he:'כרגע אין מסלול לנקודה שנבחרה'},
    something_went_wrong:{en:'Something went wrong 😮‍💨',he:'😮‍💨 משהו השתבש'},
    path_not_found:{en:'Path not found',he:'לא נמצא מסלול'},
    user_in_use:{en:'User in use',he:'משתמש בשימוש'},
    cart_is_offline:{en:'Cart is offline',he:'הרכב לא מחובר'},
    user_has_no_reservation:{en:'User has no reservation',he:'אין הזמנה פעילה'},
    try_again:{en:"Please try again",he:'בבקשה נסה שוב'},
    cart_not_found:{en:"We can't found a cart for you",he:"לא מצאנו רכב בשבילך"},
    success_feedback:{en:"Thank you for your feedback",he:"תודה על המשוב שלך"},
    no_success_feedback:{en:"We couldn't send your feedback",he:"לא יכולנו לשלוח את המשוב שלך"},
    success_operation: {en: "Operation was successful", he: "הפעולה הצליחה"},
    no_success_operation: { en: "Operation failed", he: "הפעולה נכשלה"},
    mission_close_error_title:{ en: "The cart release the ride", he: "הרכב שחרר את הנסיעה"},
  },
  feedback:{
    label:{en:'How would you rate this ride?',he:'איך היית מדרג את הנסיעה הזו?'},
    rate1:{
      label: {en:'Terrible',he:'נורא'},
      question:{en:"We are sorry you had an unpleasant experience. Would you care to tell us more?",he:"אנו מצטערים שעברת חוויה לא נעימה. אכפת לך לספר לנו יותר?"}
    },
    rate2:{
      label: {en:'Bad',he:'רע'},
      question:{en:"We are sorry you had an unpleasant experience. Would you care to tell us more?",he:"אנו מצטערים שעברת חוויה לא נעימה. אכפת לך לספר לנו יותר?"}
    },
    rate3:{
      label: {en:'Fine',he:'בסדר'},
      question:{en:"Thank you for your feedback. Is there anything we could improve?",he:"תודה על המשוב שלך. האם יש משהו שנוכל לשפר?"}
    },
    rate4:{
      label: {en:'Good',he:'טוֹב'},
      question:{en:"Thank you for your feedback! Anything else you would like to add?",he:"תודה על המשוב שלך! עוד משהו שתרצה להוסיף?"}
    },
    rate5:{
      label: {en:'Excellent',he:'מְעוּלֶה'},
      question:{en:"Thank you! We are glad you had a great experience. Anything else you would like to add?",he:"תודה! אנו שמחים שהייתה לך חוויה נהדרת. עוד משהו שתרצה להוסיף?"}
    },
    buttons:{
      submit: {en:'Submit',he:'שלח'},
      notNow: {en:'Not now',he:'לא עכשיו'},
      dontAskAgain:{en:"Don't ask me again",he:'אל תשאל אותי שוב'}
    },
    placeHolder: {en:'Feedback',he:'הערות'}


  }
}



const LanguageSelect = (props) => {

  const [selected ,setSelected] = useState (sessionStorage.getItem('language' ) || 'en');
  const [menuAnchor, setMenuAnchor] = React.useState(null);

  useEffect(() =>  props.setLanguage(selected),[selected])

  return (
    <div className="language-select" >
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        {languageMap[selected].label}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div  style={{zIndex:'9999'}}>
          <List  style={{color:'#009DDC' , margin:'0',padding:'0'}}>
            <ListSubheader style={{margin:'6px',padding:'0'}} >{languageMap[selected].select}</ListSubheader>
            {Object.keys(languageMap)?.map(item => (
              <ListItem

                button
                key={item}
                style={{margin:'6px',padding:'0'}}
                onPointerEnter={() => {
                  props.setLanguage(item)
                  sessionStorage.setItem('language' , item)
                  setSelected(item)  
                  setMenuAnchor(null);
                }}
               
              >
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;