import React ,{ createContext, useState,useEffect, useRef} from "react";
import Loading from "../../components/userLayout/loading";
import useSharedData from "../../hooks/useContext";
import {useParams } from "react-router-dom";
import GetUserDetails from "../../services/getUserDetails";
import { AppText } from "../../components/multiLanguage/multiLanguage";
import calcUserLocation from "../../utils/calcUserLocation";
import setDefaultPosition from "../../utils/setDefaultPosition";
import preReservationCheck from "../../utils/preReservationCheck";
import usePoisAndCategories from "../../hooks/usePoisAndCategories";
import useSocketForReservationData from "../../hooks/useSocketForReservationData";
import approvePickupUser from "../../utils/approvePickUpUser";
import onPoiClick from "../../utils/onPoiClickEvent";
import confirmDestinantion from "../../utils/confirmDestination";
import approveDrivingRequest from "../../services/approveDestinantionRequest";
import closeReservationRequest from "../../services/closeReservation";
import checkCartsAvailabilityFunction from "../../utils/checkCartsAvailabilityFunction";
import UseSocket from "../../hooks/useSocket";
import useGeoLocation from "../../hooks/useGeoLocation";
import useLanguageAndServerData from "../../hooks/useLangugaeAndServerContext";
import { buildFakePoi } from "../../utils/buildFakePoi";
import savePersonalPoiRequest from "../../services/savePersonalPoi";
import renderCustomAlert from "../../components/customAlert/customAlert";
import OpenTourMission from "../../services/openTourMission";
import getNonStopAreaMapping from "../../services/getNonStopAreaMapping"; // Import the service
import isKeyExistWithinArrayOfObjects from '../../utils/isKeyExistWithinArrayOfObjects';
import getAllOnlineCarts from "../../services/getAllOnlineCarts";

function getPOIByNameInAllArrays(pois, poi_name) {
    const keys = Object.keys(pois);
    for (let i = 0; i < keys.length; i++) {
        const poisArray = pois[keys[i]];
        for (let j = 0; j < poisArray.length; j++) {
            if (poisArray[j].poi_name === poi_name) {
                return poisArray[j];
            }
        }
    }
    return null;
}


export const ReservationContext = createContext({});

const ReservationProvider = ({ children  })=>{
    
    //user data
    const {user_id} =  useParams();
    const [userDetails ,setUserDetails] =useState(null);
    const {language} = useLanguageAndServerData();
 
    const {mapData} = useSharedData();
    const map_default_point = mapData?.data?.map_default_point ?? null;


    // Just for testing and debugging
    const isDemoUser = user_id ? user_id === '9b4b976f-69c0-4baf-ac9a-26cf9a3ff510' : null;
    const geoLocationData = useGeoLocation(isDemoUser);

    let [userLocation, locationAccuracy, locationError, setLocationError, startWatchUserLocation, stopWatchingUserLocation, isFinishWatchUserLocation,locationEnabled,requestUserLocationPermission] = Array.isArray(geoLocationData)  ? geoLocationData : [null, null, null,null,null];
    locationEnabled = locationEnabled === undefined ? false : locationEnabled;

    // Just for testing and debugging - ensure accuracy circle on demo user !!!
    userLocation = isDemoUser && map_default_point ? map_default_point : userLocation;

    const [loading, setLoading ] =useState(false);
    
   
    //Map,poi,categories data 
    const {poisData,savedTours ,setPoisData,getPoisData} = usePoisAndCategories({user_id,language});
    const { pois, categories, categoryNameById } = poisData || {};
    // State to store the non-stop area data
    const [nonStopArea, setNonStopArea] = useState(null);
    const [isNonStoppableArea, setIsNonStoppableArea] = useState(false);

    //represents the user location,including when the user dragged the map or change his location by choose another poi.
    const [userCurrentLocation,setUserCurrentLocation] = useState(null);
    const [watchingUserLocationIsActive,setWatchingUserLocationIsActive] = useState(true);
    const [userFarFromRoad,setUserFarFromRoad] = useState(false);

    //active reservation  
    const [reservation,setReservation] = useState(null);
    const [cartStatus,setCartStatus] = useState(null);
    const [missionCloseError, setMissionCloseError] = useState(false);
    const prevReservationRef = useRef(null);
    const [activeCarsList, setActiveCarsList] = useState([]);
    const [isCartModeManual, setIsCartModeManual] = useState(false);

    //Messages to display 
    const[secondaryTopBarMessageText,setSecondaryTopBarMessageText] = useState('');
    const[mapIconLabelText,setMapIconLabelText] = useState('');

    //POIs container 
    const [pickUpFromText,setPickUpFromText] = useState(AppText.map_label_massage.choose_location[language]);
    const[chooseDestinationByPinPoint,setChooseDestinationByPinPoint] = useState(null);
    const [startInteractMapForChooseDestinationPoint,setStartInteractMapForChooseDestinationPoint] = useState(false);

    //open dialogs states
    const [showUserInUseDialog,setShowUserInUseDialog]=useState(false);
    const [openReleaseDialog,setOpenReleaseDialog] = useState(false);
    const [openSavePoiNameInputDialog, setOpenSavePoiNameInputDialog] = useState(false);


    //auto release cart 
    const [autoReleaseEta,setAutoReleaseEta] = useState(null);
    const autoReleaseIntervalIdRef = useRef(null);

    //client socket
    const {socket} = UseSocket();
   
    //bottomBar state
    const [actionSent,setActionSent] = useState(false);
    const [choosenPoi,setChoosenPoi] = useState(null);
    const [showPoi,setShowPoi] = useState(false);

    //calc path state
    const [searchPath,setSearchPath] = useState(false);
    const [showCountDownTimer,setShowCountDownTimer] = useState(false);

    //cart Availability
    const [isCartAvailableForReservation,setIsCartAvailableForReservation] = useState(null);
    const [cartWillBeAvailableIn_seconds,setCartWillBeAvailableIn_seconds] = useState(-1);
    const [cartError, setCartError] = useState(false); // State for cart error

    //rate driving states
    const [showRateDialog,setShowRateDialog] = useState(false);
    const [dontAskMeAgain, setDontAskMeAgain] = useState(() => {
        const storedValue = localStorage.getItem('dontAskMeAgain');
        return userDetails?.app_config?.ask_for_rate_ride ? ( storedValue ? JSON.parse(storedValue) : false ) : false ;
    });









    useEffect(() => {
        const fetchActiveCars = async () => {
            const cars = await getAllOnlineCarts();
            if (Array.isArray(cars)) {
                setActiveCarsList(cars);
            } else {
                console.log("ERROR: Active cars is not an array");
            }
        };

        fetchActiveCars();
    }, []);


    useEffect(() => {
        const cartMode = reservation?.cartInfo?.vehicle_mode;
        if (cartMode) {
            setIsCartModeManual(cartMode === "VEHICLE_MODE_COMPLETE_MANUAL");
        } else {
            setIsCartModeManual(false);
        }
    }, [reservation?.cartInfo?.vehicle_mode]);









    
      useEffect(() => {
        localStorage.setItem('dontAskMeAgain', JSON.stringify(dontAskMeAgain));
    }, [dontAskMeAgain]);

    const prevAskForRateRideRef = useRef(userDetails?.app_config?.ask_for_rate_ride);
    
    useEffect(() => {
        const currentAskForRateRide = userDetails?.app_config?.ask_for_rate_ride;
        const prevAskForRateRide = prevAskForRateRideRef.current;

        if (currentAskForRateRide !== prevAskForRateRide) {
            setDontAskMeAgain(!currentAskForRateRide);
            prevAskForRateRideRef.current = currentAskForRateRide;
        }
    }, [userDetails?.app_config?.ask_for_rate_ride]);

    useEffect(() => {
        // Check for cart malfunctions whenever cartStatus changes
        if (cartStatus && cartStatus.cart_decision?.malfunction?.errors_list.length > 0) {
          setCartError(true);
        } else {
          setCartError(false); // Reset error if the list becomes empty
        }
      }, [cartStatus]); // Run this effect only when cartStatus changes


      useEffect(() => {
            const fetchNonStopAreaData = async () => {
                try {
                    const data = await getNonStopAreaMapping();
                    setNonStopArea(data);
                } catch (error) {
                    console.error('Error fetching non-stop area data:', error);
                }
            };
            fetchNonStopAreaData();
        },[]);

    useEffect(() => {
        const fetchNonStopAreaData = async () => {
            try {
                const data = await getNonStopAreaMapping();
                setNonStopArea(data);
            } catch (error) {
                console.error('Error fetching non-stop area data:', error);
            }
        };
    
        if (!prevReservationRef.current && reservation) {
            // Fetch data if reservation was previously null and now has a value
            fetchNonStopAreaData();
        }
    
        // Update the previous reservation ref
        prevReservationRef.current = reservation;
    }, [reservation?.user_name]);
    
    
    useEffect(() => {
        if (cartStatus?.mission_status?.current_point_parameters && !isCartModeManual) {
            const pointParametersArray = cartStatus.mission_status.current_point_parameters;
            let arrayString = pointParametersArray.replace(/'/g, '"');
            let array = JSON.parse(arrayString);
            const keyToCheck = "non_stop_area";
            const isInsideNonStoppableArea = isKeyExistWithinArrayOfObjects(array, keyToCheck);
            setIsNonStoppableArea(isInsideNonStoppableArea);
            setOpenReleaseDialog(false)
        }else{ setIsNonStoppableArea(false); }
    }, [cartStatus?.mission_status?.current_point_parameters,isCartModeManual]);
    
    useSocketForReservationData({
        user_id,
        socket,
        setReservation,
        setCartStatus,
        onCloseReservation,
        reservation,
        handleCartAvailabilityDataFromServer,
        connectionError
    })

    //states from userDetails
    let autoClosingReservationTimeInSec = 30;
    let nearByPoiDistanceInMeters = 30;
    let showServiceByOneCartButton = false;
    if (userDetails?.app_config) {
      autoClosingReservationTimeInSec = userDetails.app_config.pickup_timeout_sec ?? autoClosingReservationTimeInSec;
      nearByPoiDistanceInMeters = userDetails.app_config.nearByPoiDistanceInMeters ?? nearByPoiDistanceInMeters;
      showServiceByOneCartButton = userDetails.app_config.showServiceByOneCartButton ?? showServiceByOneCartButton;
    }
    const handleStartInteractMapForChooseDestinationPoint = ()=>{
        setChoosenPoi(null)
        setPickUpFromText(AppText.map_label_massage.choose_location[language]);
        setStartInteractMapForChooseDestinationPoint(true);
    }

    const handleStopInteractMapForChooseDestinationPoint = ()=>{
            setStartInteractMapForChooseDestinationPoint(false);
            handleDestinationPinPointLocationChanged(null);
    }

    const handleDestinationPinPointLocationChanged =(position)=>{
        setChooseDestinationByPinPoint(position);
    }

    async function userDetailsFunction(){
        try {
            // Call both asynchronous functions simultaneously
            const [fullUserDetails, preReservationCheckResponse] = await Promise.all([
                GetUserDetails(user_id),
                triggerPreReservationCheck()
            ]);
            
            setUserDetails(fullUserDetails);
            setLoading(false);

        } catch (error) {
            console.error("Error when call GetUserDetails and triggerPreReservationCheck  :", error);
            setLoading(false);
        }
    }

    async function triggerPreReservationCheck(){
        const response = await preReservationCheck({
            setSecondaryTopBarMessageText,
            user_id,
            setShowUserInUseDialog,
            connectionError,
            language
        })
        return ;
    };
    
    
    function handleCartAvailabilityDataFromServer (p_availability_response){
        const cartsAvailabiltyData = p_availability_response;
        checkCartsAvailabilityFunction({
            setIsCartAvailableForReservation,
            setCartWillBeAvailableIn_seconds,
            cartsAvailabiltyData,
        })
    }

    const triggerConfirmDestination =async (p_poi_location,p_poi_name,setShowPoi)=>{
       const response = await  confirmDestinantion({
            p_poi_location,
            p_poi_name,
            setSecondaryTopBarMessageText,
            setReservation,
            setShowPoi,
            setSearchPath,
            language,
            connectionError,
            setUserFarFromRoad,
            user_id,
        });
        return response;
    }

    const triggerTourClick =async (p_mission)=>{
        const response = await OpenTourMission(p_mission,user_id)
        console.log("triggerTourClick " ,response);
        if(response?.data === 'ok'){
            //success
            renderCustomAlert({
                id: 'start_shuttle_success',
                title: "Perfect🙂 Tour is setted",
                bodyText: "Cart will start driving within a moment.",
                duration: 2000
            });
        }else{
            renderCustomAlert({
                id: 'something_went_wrong',
                title: AppText.alert.try_again[language],
                bodyText: AppText.alert.something_went_wrong[language],
                type: 'error',
                language: language,
                duration: 2000
            });
        }
    }

    const triggerPoiClick = async (p_poi,setShowPoi,isPickup) => { 
      
        const response = await onPoiClick({
            setSecondaryTopBarMessageTextIfChanged,
            setMapIconLabelText,
            stopWatchingUserLocationFunction,
            setPickUpFromText,
            setUserCurrentLocation,
            setShowPoi,
            triggerConfirmDestination,
            language,
            p_poi,
            isPickup,
            setChoosenPoi,
            choosenPoi,
            language,
        })
        return response;
           
    }
    
    const clearAutoReleaseEtaInterval = ()=>{
        if(autoReleaseIntervalIdRef.current){
            setAutoReleaseEta(null);
            clearInterval(autoReleaseIntervalIdRef.current);
            autoReleaseIntervalIdRef.current = null;
        }
    }

    const triggerApprovePickUpUser = ()=>{
        approvePickupUser({
            user_id,
            connectionError,
            clearAutoReleaseEtaInterval,
            setSecondaryTopBarMessageText,
            language,
            setActionSent
        });
    }


    const updateReservationOnlyOnceRef = useRef(false);
    useEffect(()=>{
        
        if(pois && userDetails && !updateReservationOnlyOnceRef.current ){
            if(userDetails && userDetails.user_mission_status ){

                updateReservationOnlyOnceRef.current = true;

                stopWatchingUserLocationFunction();
                setCartStatus(userDetails.user_mission_status.cartInfo);
                setReservation(userDetails.user_mission_status);
                if(!userDetails.user_mission_status?.mission_paths_list[0]?.path_type.includes("PICKUP")){
                    setPickUpFromText(userDetails.user_mission_status.dest_name)
                    const poi = getPOIByNameInAllArrays(pois,userDetails.user_mission_status.dest_name);
                    if(poi){
                        setChoosenPoi(poi);
                    }else{
                        const path = userDetails.user_mission_status?.mission_paths_list[0]?.path;
                        if(path){
                            const fakePoi = buildFakePoi(null,path.poi_location,path.dest_name)
                            setChoosenPoi(fakePoi);
                        }
                    }
                }
            }
        }
    },[pois,userDetails])
  
    const changeUserLocationHandler =(position)=>{
        setUserCurrentLocation(position);
    }
                    
    const stopWatchingUserLocationFunction =()=>{
        if(watchingUserLocationIsActive){
            setWatchingUserLocationIsActive(false); 
            stopWatchingUserLocation();
        }
    }

    function connectionError (){
        console.log("Connection error");
        setSecondaryTopBarMessageText(AppText.messages.connection_error.title[language]);
    }

    const closeReservation = async ()=>{
        const closeResponse = await closeReservationRequest(user_id);
        if(closeResponse==="received"){
            onCloseReservation();
        }
    }

    // Check if the new state value is different from the previous state value before updating the state
    const setSecondaryTopBarMessageTextIfChanged = (newMessage) => {
        setSecondaryTopBarMessageText(prevState => {
            if (prevState !== newMessage) {
                return newMessage;
            }
            return prevState;
        });
    };


    const startIntervalForAuthoCloseReservation =(p_autoClosingReservationTImeInSeconds=autoClosingReservationTimeInSec)=>{
        
        if(p_autoClosingReservationTImeInSeconds){
            let index =1;
            const intervalId = setInterval(()=>{
                if(index === p_autoClosingReservationTImeInSeconds){
                    clearInterval(intervalId);
                }
                setAutoReleaseEta(p_autoClosingReservationTImeInSeconds - index);
                index ++;
            },1000);
            autoReleaseIntervalIdRef.current = intervalId ;

        }
    }


    const clearMapIconLabelText = ()=>{
        if(mapIconLabelText !== ""){
            setMapIconLabelText("");
          }
    }



    function onCloseReservation (){
        console.log("onCloseReservation");

        if (reservation?.reservation_status !== "PATH_TYPE_TRANSPORTATION_FINISHED" && reservation?.reservation_status !== null) {
            setMissionCloseError(true); // Set error state if mission did not close well
        } else {
            setMissionCloseError(false); // Reset the error state if everything is fine
        }

        if(!dontAskMeAgain && reservation?.reservation_status==="PATH_TYPE_TRANSPORTATION_FINISHED"){ //reservation_status: "PATH_TYPE_TRANSPORTATION_FINISHED"
            setShowRateDialog(true)
            //console.log(reservation);
            sessionStorage.setItem('lastClosedReservationId', reservation?.mission_id);
        }
        
        resetUserLocation();
        setReservation(null);
        handleStopInteractMapForChooseDestinationPoint();
        setShowPoi(prevState => prevState ? false : prevState);
        setChoosenPoi(prevState => prevState != null ? null :prevState );
        setCartStatus(null);
        setSecondaryTopBarMessageText('');
        setPickUpFromText(AppText.map_label_massage.choose_location[language]);
        clearAutoReleaseEtaInterval();

        // if show rate comp 
        //setShowRateComp(true);
   
    }
        // Effect to render custom alert when missionCloseError is true
        useEffect(() => {
            if (missionCloseError) {
                renderCustomAlert({
                    id: 'mission-close-error',
                    title: AppText.alert.mission_close_error_title[language],
                    bodyText: "",
                    type: 'error',
                    language: language,
                    duration: 3000
                });
                setMissionCloseError(false); // Reset the error state after showing the alert
            }
        }, [missionCloseError, language]);


        const resetUserLocation = () => {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
              if (result.state === 'granted') {
                // Permission granted
                startWatchUserLocation();
                setWatchingUserLocationIsActive(true);
              } else {
                // For 'prompt' and 'denied', request permission (this will set locationError in useGeoLocation)
                requestUserLocationPermission();
              }
              if(isDemoUser){
                setUserCurrentLocation(null);
               }
            });
          };
          
          
          useEffect(() => {
            if (locationError) {
              renderCustomAlert({
                id: 'location-permission-denied',
                title: 'Location Access Needed',
                bodyText: locationError,
                type: 'error',
                language: language,
                duration: 5000,
              });
          
              // Clear the error after displaying the alert
              setLocationError(null);
            }
          }, [locationError,language,setLocationError]);
          
          
          
        
        

    const saveNewPoiAndUpdatePois = async (p_newPoi) => {

        if(!p_newPoi) return;
        
        const newPoiObject = await savePersonalPoiRequest(user_id, p_newPoi);
        if (newPoiObject) {
            if(typeof newPoiObject === "object") {
                
                if(reservation  ){
                    if(!choosenPoi || choosenPoi?.poi_id !== newPoiObject.poi_id){
                        setChoosenPoi(newPoiObject)
                    }
                }
                if(pickUpFromText !== newPoiObject.poi_name){
                    setPickUpFromText(newPoiObject.poi_name)
                }
                const updatedPoisData = { ...poisData };
                const pois = updatedPoisData.pois;
                
                const category = newPoiObject.poi_category_id;
                
                if (!pois[category]) {
                    pois[category] = [];
                }
                pois[category].push(newPoiObject);
            
                setPoisData(updatedPoisData);
            }else if(typeof newPoiObject === 'string'){
                    if(newPoiObject === "point is not close to any road"){
                        renderCustomAlert({
                            id: 'point_is_not_close_to_any_road',
                            title: AppText.alert.we_sorry[language],
                            bodyText: AppText.alert.point_not_close_to_any_road[language],
                            type: 'info',
                            language: language,
                            duration: 2000
                        });
                    }else{
                        renderCustomAlert({
                            id: 'something_went_wrong',
                            title: AppText.alert.try_again[language],
                            bodyText: AppText.alert.something_went_wrong[language],
                            type: 'error',
                            language: language,
                            duration: 2000
                        });
                    }
            }
        }
      };

    function triggerSetDefaultPosition (p_set_only_position = false,setLocationFuntion= setUserCurrentLocation){

        setDefaultPosition({
            language,
            mapData,
            setMapIconLabelText,
            setLocationFuntion,
            setPickUpFromText,
            p_set_only_position:p_set_only_position
        })
    }

    const approveDestination = async (p_choosenPoiId)=>{

        setSecondaryTopBarMessageText(AppText.massageBar.going_in[language]);
        setShowCountDownTimer(true);
        setTimeout(async ()=>{
            setActionSent(true);
            setShowCountDownTimer(false);
            const approveDriveResponse = await approveDrivingRequest(user_id,p_choosenPoiId,false);
            if(approveDriveResponse !=='ok'){
                setSecondaryTopBarMessageText(AppText.massageBar.something_went_wrong[language]);
                setActionSent(false)
            }

   
        },3*1000)
    }


    useEffect(()=>{
        if(!mapData ) return; 
        if (!locationEnabled ) {
            // Geo location API not available
            console.log("GeoLocation API not available")    
            stopWatchingUserLocation()
            setWatchingUserLocationIsActive(false);
            triggerSetDefaultPosition();
            return;

        }

    },[mapData , locationEnabled])

    useEffect(() => {
        
        if(!mapData) return;
        if ((isFinishWatchUserLocation && watchingUserLocationIsActive && userCurrentLocation && locationEnabled)) {
            setWatchingUserLocationIsActive(false);
            // Check if userCurrentLocation exists and has lat and lng properties
            if (userCurrentLocation && userCurrentLocation.lat && userCurrentLocation.lng) {
                calcUserLocation({
                    language,
                    lat: userCurrentLocation.lat,
                    lon: userCurrentLocation.lng,
                    setMapIconLabelText,
                    setUserCurrentLocation,
                    triggerSetDefaultPosition,
                    mapData
                });
            }
        }else{
            if(isFinishWatchUserLocation && userCurrentLocation == null){
                triggerSetDefaultPosition();
            }
        }
    }, [mapData,isFinishWatchUserLocation,locationEnabled, watchingUserLocationIsActive, userCurrentLocation]);
    

    useEffect(()=>{
        if( mapData && (!isFinishWatchUserLocation ) ){
            setUserCurrentLocation(userLocation);
        }
    },[userLocation,mapData ,isFinishWatchUserLocation])


    useEffect(()=>{
        userDetailsFunction();
    },[])

        if(!loading ){
            return <ReservationContext.Provider
                value={{
                    reservation,
                    cartStatus,
                    userCurrentLocation,
                    mapIconLabelText,
                    setMapIconLabelText,
                    secondaryTopBarMessageText,
                    changeUserLocationHandler,
                    watchingUserLocationIsActive,
                    stopWatchingUserLocationFunction,
                    isFinishWatchUserLocation,
                    pickUpFromText,
                    setPickUpFromText,
                    userDetails,
                    showUserInUseDialog,
                    setShowUserInUseDialog,
                    connectionError,
                    setSecondaryTopBarMessageText,
                    pois,
                    poisData,
                    categories,
                    categoryNameById,
                    clearMapIconLabelText,
                    setReservation,
                    startIntervalForAuthoCloseReservation,
                    autoReleaseEta,
                    triggerApprovePickUpUser,
                    socket,
                    actionSent,
                    setActionSent,
                    triggerPoiClick,
                    searchPath,
                    setSearchPath,
                    showCountDownTimer,
                    approveDestination,
                    choosenPoi,
                    closeReservation,
                    openReleaseDialog,
                    setOpenReleaseDialog,
                    user_id,
                    resetUserLocation,
                    setSecondaryTopBarMessageTextIfChanged,
                    isCartAvailableForReservation,
                    cartWillBeAvailableIn_seconds,
                    showPoi,
                    setShowPoi,
                    chooseDestinationByPinPoint,
                    startInteractMapForChooseDestinationPoint,
                    handleDestinationPinPointLocationChanged,
                    handleStartInteractMapForChooseDestinationPoint,
                    handleStopInteractMapForChooseDestinationPoint,
                    userFarFromRoad,
                    setUserFarFromRoad,
                    setPoisData,
                    getPoisData,
                    saveNewPoiAndUpdatePois,
                    openSavePoiNameInputDialog, 
                    setOpenSavePoiNameInputDialog,
                    locationAccuracy,
                    setChoosenPoi,
                    userLocation,
                    nearByPoiDistanceInMeters,
                    showServiceByOneCartButton,
                    showRateDialog,
                    setShowRateDialog,
                    dontAskMeAgain,
                    setDontAskMeAgain,
                    savedTours,
                    triggerTourClick,
                    cartError,
                    nonStopArea,
                    isNonStoppableArea,
                    isCartModeManual,
                    setIsCartModeManual,

                }}
            >
                    { children  }
            </ReservationContext.Provider>
        }else{
            return (<Loading/> );
        }
    
    }
   
    export default ReservationProvider;
